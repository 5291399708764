import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useReportsList(currentReportType, listClientName) {
  // Use toast
  const toast = useToast()

  const refReportListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'report_id', sortable: true },
    { key: 'client_display_name', label: 'Client', sortable: true },
    { key: 'sitename', label: 'Site', sortable: true },
    { key: 'user_display_name', label: 'Reported By', sortable: true },
    { key: 'report_date_local', label: 'Report Date/Time', sortable: true },
    { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalReports = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('report_id')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const statusFilter = ref(false)
  const reportsFilter = ref(currentReportType)
  const reportsList = ref(null)
  const clientFilter = ref(listClientName)
  const reportsListComplete = ref(null)
  const clientList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refReportListTable.value ? refReportListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReports.value,
    }
  })

  const refetchData = () => {
    console.log('refetchData')
    refReportListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, reportsFilter, clientFilter], () => {
    console.log('Filter Watch Triggered')
    refetchData()
  })

  const fetchReports = (ctx, callback) => {
    console.log('fetchReports - useReportsList')
    console.log(ctx)
    console.log(clientFilter)
    // if (ability.can('read', 'reports') && clientFilter.value !== null && clientFilter.value !== undefined && reportsFilter.value !== null && reportsFilter.value !== undefined) {
    if (ability.can('read', 'reports')) {
      console.log('fetchReports PASSED')
      const qvars = {
        forceRefresh: true,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        reportType: reportsFilter.value,
        clientFilter: clientFilter.value,
        approved: statusFilter.value,
      }
      console.log(qvars)
      store
        .dispatch('app-report/fetchReports', qvars)
        .then(response => {
          const { reports } = response.data
          console.log(callback)
          callback(reports)
          reportsListComplete.value = reports
          totalReports.value = response.data.totalReports
          const tmp = []
          reports.forEach(item => {
            tmp.push({ label: item.report_id, value: item.report_id })
          })
          reportsList.value = tmp
          console.log('reportsList')
          console.log(reportsList)
        })
        .catch(error => {
          console.log(error) // No current user
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching reports list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // Get the list of clients
  const qvars = {}
  qvars.status = 'active'
  qvars.q = null
  qvars.service = null

  store
    .dispatch('app-client/fetchClients', qvars)
    .then(response1 => {
      const { clients } = response1.data
      const tmp1 = []
      clients.forEach(item => {
        tmp1.push({ label: item.display_name, value: item.clientname })
      })
      clientList.value = tmp1
    })
    .catch(error => {
      console.log(error) // No current user
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching client list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI -------------------------------------*
  // *===============================================---*
  const resolveReportStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'warning'
    return 'warning'
  }

  const resolveReportStatusVariantLabel = status => {
    if (status === true) return 'Approved'
    if (status === false) return 'Unapproved'
    return 'Unapproved'
  }

  const resolveReportTypeLabel = (reportType, reportTypes) => {
    let theLabel = ''
    // eslint-disable-next-line consistent-return
    reportTypes.forEach(item => {
      if (item.value === reportType) {
        theLabel = item.label
      }
    })
    return theLabel
  }

  return {
    fetchReports,
    tableColumns,
    perPage,
    currentPage,
    totalReports,
    reportsList,
    reportsListComplete,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refReportListTable,

    resolveReportStatusVariant,
    resolveReportStatusVariantLabel,
    resolveReportTypeLabel,
    refetchData,

    clientList,

    // Extra Filters
    statusFilter,
    reportsFilter,
    clientFilter,
  }
}
