<template>

  <div v-if="reportTypes && $can('read', 'reports')">

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <reports-list-filters
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :reports-filter.sync="reportsFilter"
        :report-types="reportTypes"
        :client-list="clientList"
        :client-filter.sync="clientFilter"
      />
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.reports.fetchingReportList"
              @click="refreshReportList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
              @click="isAddNewReportSidebarActive = true"
            >
              <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add Report</span>
            </b-button>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- Table -->
      <b-table
        ref="refReportListTable"
        class="position-relative"
        :items="fetchReports"
        responsive
        :fields="tableColumns"
        primary-key="reportID"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: Report ID-->
        <template #cell(report_id)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-reports-' + data.item.report_type, params: { reportID: data.item.report_id, SK: data.item.SK, report_type: data.item.report_type } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.report_id }}
            </b-link>
          </div>
        </template>
        <!-- Column: Client Name -->
        <template #cell(client_display_name)="data">
          <div class="text-nowrap">
            {{ data.item.client_display_name }}
          </div>
        </template>
        <!-- Column: report type -->
        <template #cell(report_type)="data">
          <div class="text-nowrap">
            {{ resolveReportTypeLabel(data.item.report_type, reportTypes) }}
          </div>
        </template>
        <!-- Column: user display name -->
        <template #cell(user_display_name)="data">
          <div class="text-nowrap">
            {{ data.item.user_display_name }}
          </div>
        </template>
        <!-- Column: report date local -->
        <template #cell(report_date_local)="data">
          <div class="text-nowrap">
            {{ data.item.report_date_local.replace('T', ' ') }}
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveReportStatusVariant(data.item.approved)}`"
            class="text-capitalize"
          >
            {{ resolveReportStatusVariantLabel(data.item.approved) }}
          </b-badge>
        </template>

      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalReports"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import router from '@/router'
// import { ref, watch, onUnmounted } from '@vue/composition-api'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import { useRouter } from '@core/utils/utils'
import ReportsListFilters from './ReportsListFilters.vue'
import useReportsList from './useReportsList'
import reportStoreModule from '../reportsStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'

export default {
  components: {
    ReportsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,

    vSelect,
  },
  computed: {
    isFetchingReportList() {
      console.log('isFetchingReportList')
      console.log(this.$store.getters.getFetchingReportList)
      return this.$store.getters.getFetchingReportList
    },
  },
  setup() {
    console.log('ReportsList Setup')
    const listReportType = router.currentRoute.params.currentReportType
    console.log(listReportType)
    let listClientName = router.currentRoute.params.clientname
    if (listClientName === undefined || listClientName === null) {
      listClientName = 'CancomSecurity'
    }
    console.log(listClientName)
    // Get the current user's data
    const userData = getUserData()

    // Register module
    const REPORT_APP_STORE_MODULE_NAME = 'app-report'
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.registerModule(REPORT_APP_STORE_MODULE_NAME, reportStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORT_APP_STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    // Setup other vars
    const isAddNewReportSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Unapproved', value: false },
      { label: 'Approved', value: true },
    ]
    const reportTypes = store.state.appSettings.report_types

    const {
      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReports,
      reportsList,
      reportsListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refReportListTable,
      refetchData,

      // UI
      resolveReportRoleVariant,
      resolveReportRoleIcon,
      resolveReportStatusVariant,
      resolveReportStatusVariantLabel,
      resolveReportTypeLabel,

      clientList,

      // Extra Filters
      statusFilter,
      reportsFilter,
      clientFilter,
    } = useReportsList(listReportType, listClientName)

    return {
      userData,
      // Sidebar
      isAddNewReportSidebarActive,

      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReports,
      reportsList,
      reportsListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refReportListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveReportRoleVariant,
      resolveReportRoleIcon,
      resolveReportStatusVariant,
      resolveReportStatusVariantLabel,
      resolveReportTypeLabel,

      statusOptions,
      clientList,

      // Extra Filters
      statusFilter,
      reportsFilter,
      clientFilter,

      reportTypes,
    }
  },
  methods: {
    refreshReportList() {
      this.$store.state.reports.fetchingReportList = true
      this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
